<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Nuevo Horario</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-select
                v-model="schedule.day"
                :items="days"
                label="Día"
                required
                outlined
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-menu
                ref="menu"
                v-model="timePickerStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="schedule.start"
                    label="Hora de Inicio"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timePickerStart"
                  v-model="schedule.start"
                  ampm-in-title
                  format="24h"
                  scrollable
                  :max="schedule.end"
                  @click:minute="$refs.menu.save(schedule.start)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-menu
                ref="menuEnd"
                v-model="timePickerEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="schedule.end"
                    label="Hora de Finalización"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timePickerEnd"
                  v-model="schedule.end"
                  ampm-in-title
                  format="24h"
                  scrollable
                  :min="schedule.start"
                  @click:minute="$refs.menuEnd.save(schedule.end)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="changeModal()">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="add()">
          Agregar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AddSchedule",
  data() {
    return {
      days: [
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
        "Todos los dias",
      ],
      timePickerStart: null,
      timePickerEnd: null,
      dialog: false,
    };
  },
  props: { schedule: Object, clean: { type: Function } },
  mounted() {},
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    add: function() {
      this.isLoading = true;
      this.$http
        .post(this.api + "schedule", this.toFormData(this.schedule))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.changeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
  },
};
</script>

<style></style>
